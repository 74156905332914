var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  $(document).on('inv_status_data:updated', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list', $(this));

    // Not all .js-product have an inventory-status display.
    if (!$statusList.length) {
      return;
    }
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $statusList = $('.js-inv-status-list', $(this));

    if ($statusList.length < 1) {
      return null;
    }
    Drupal.behaviors.inventoryStatusV1.selectSku($statusList, skuBaseId);
  });

  $(document).on('product:init', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list', $(this));

    // Not all .js-product have an inventory-status display.
    if (!$statusList.length) {
      return;
    }

    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  });

  Drupal.behaviors.inventoryStatusV1 = {
    // eslint-disable-next-line complexity
    update: function ($statusList, skuData) {
      var skuBaseId = $statusList.data('sku-base-id');

      if (!skuData || !skuData.INVENTORY_STATUS) {
        return null;
      }
      if (skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }

      var $statusMessage = $('.js-inv-status-message', $statusList);
      var inventoryStatusMessage = null;
      var reaction = prodcat.control.getInventoryStateReaction(skuData);

      if (reaction.message_ts_key) {
        inventoryStatusMessage = prodcat.ui.getProductTranslation(reaction.message_ts_key);
      }

      if (inventoryStatusMessage) {
        $statusMessage.html(inventoryStatusMessage);
        $statusMessage.addClass('active');
      } else {
        $statusMessage.empty();
        $statusMessage.removeClass('active');
      }

      // For FE to style based on state.
      $statusMessage.attr('data-inventory-state-key', reaction.inventoryStateKey);

      $statusList.trigger('inv_status_display:updated');
    },

    selectSku: function ($statusList, skuBaseId) {
      $statusList.data('sku-base-id', skuBaseId);
      var skuData = prodcat.data.getSku(skuBaseId);

      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  };
})(jQuery);
